import useTranslation from 'next-translate/useTranslation';

import { TranslationKey } from '@admin/utils/TranslationKey';
import { Icon } from '@common/atoms/Icon';

import styles from './InsertJobsOverview.module.scss';

interface Props {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const InsertJobsOverview = ({ onClick }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    return (
        <button
            onClick={onClick}
            className={styles.InsertJobsOverview}
            aria-label={__translate('insertJobsOverview.button')}
        >
            <Icon.iframe
                svgProps={{
                    width: 29,
                    height: 21,
                    viewBox: '0 0 29 21',
                }}
            />
            <span>{__translate('insertJobsOverview.button')}</span>
        </button>
    );
};
