import { FieldType, FormFields } from '@admin/types';

export const formFields: FormFields = {
    iframeUrl: {
        type: FieldType.text,
        id: 'iframeUrl',
    },
    aspectRatio: {
        type: FieldType.select,
        options: [
            { title: 'auto', value: 'auto' },
            { title: '21:9', value: '21/9' },
            { title: '16:9', value: '16/9' },
            { title: '8:5', value: '8/5' },
            { title: '4:3', value: '4/3' },
            { title: '3:2', value: '3/2' },
            { title: '3:1', value: '3/1' },
            { title: '1:1', value: '1/1' },
            { title: '2:3', value: '2/3' },
            { title: '3:4', value: '3/4' },
            { title: '5:8', value: '5/8' },
            { title: '9:16', value: '9/16' },
        ],
        id: 'aspectRatio',
    },
};
