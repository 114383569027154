import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode, CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { BaseModalProvider } from '@admin/organisms/BaseModal';

import { IframeArticleBlock } from '.';

export interface IframeNodeData {
    iframeUrl?: string;
    aspectRatio?: string;
    type: CustomNodeTypes.iframe;
}

export class IframeNode extends CustomNode<IframeNodeData, IframeNode>(
    (data: IframeNodeData): IframeNode => new IframeNode(data),
) {
    static override __type = CustomNodeTypes.iframe;

    constructor(data: IframeNodeData, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static override clone(node: IframeNode): IframeNode {
        return new IframeNode(node.__data, node.__format, node.__key);
    }

    handleOnChange(editor: LexicalEditor) {
        return (data: IframeNodeData) => {
            editor.update(() => {
                const writable: IframeNode = this.getWritable();

                writable.__data.iframeUrl = data.iframeUrl;
                writable.__data.aspectRatio = data.aspectRatio;
            });
        };
    }

    override decorate(editor: LexicalEditor, _config: EditorConfig) {
        return (
            <BaseModalProvider>
                <IframeArticleBlock
                    {...this.__data}
                    onChange={this.handleOnChange(editor)}
                    onDelete={this.handleOnDelete(editor)}
                />
            </BaseModalProvider>
        );
    }
}
